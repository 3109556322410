<template>
  <div>
    <vue-apex-charts
      type="line"
      height="240"
      :options="chartData.chartOptions"
      :series="chartData.series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      revenue: {},
      revenueComparisonLine: {
        series: [
          {
            name: 'Time Planned',
            data: [45, 47, 50, 40, 44],
          },
          {
            name: 'Actual Hours',
            data: [33, 45, 58, 45, 46],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
            stacked: false,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#d0ccff', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: this.chartData.chartOptions.xaxis.categories,
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: this.chartData.title !== 'Time Planning Budget' ? {
            // opposite: isRtl,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999999 ? `${(val / 1000000).toFixed(0)} mil` : `${val / 1000}k`
              },
            },
          } : {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return `${val} Hrs`
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: true },
          },
        },
      },
    }
  },
  // created() {
  //   this.$http.get('/card/card-analytics/revenue').then(res => { this.revenue = res.data })
  // },
}
</script>
