<template>

  <div>
    <vue-apex-charts
      type="bar"
      height="400"
      :options="chartData.chartOptions"
      :series="chartData.series"
    />

  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
  },
  props: ['chartData'],
  data() {
    return {
      // apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
}
</script>
