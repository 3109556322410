<template>
  <div>
    <div
      class="mb-2"
      style="height: inherit; padding-left: 1rem; padding-right: 1rem"
    >
      <!-- Overview Analytics -->
      <div class="row justify-content-between">
        <div
          v-for="(item, index) in overview.type"
          v-show="config.overviews"
          :key="index"
          class="col-sm-12 col-md p-0"
          @click="modalOpen(item)"
        >
          <goal-overview
            style="height: 360px"
            :data="item"
            class="onHover cursorIcon"
            :class="index === 0 ? '' : 'ml-1'"
          />
        </div>
      </div>

      <!-- Manhours Spend -->
      <div class="row justify-content-between mb-2">
        <card-analytic-revenue
          v-show="config.workHours"
          class="p-0 m-0 col-12"
        />
      </div>

      <!-- total cost budget -->
      <div class="row justify-content-between">
        <budget-bar-chart
          v-show="config.budget"
          class="p-0 m-0 col-12"
        />
      </div>

      <div class="row justify-content-between mt-2">
        <!-- Non-compliance issues -->
        <div class="p-0 m-0 col-6">
          <non-compliance-issues
            v-show="config.issues"
            class=""
            style="height: 100%"
          />
        </div>
        <!-- Weekly Task -->
        <div
          v-show="config.weekTask"
          class="m-0 col-6"
        >
          <weekly-task class="p-0 m-0" />
        </div>

        <!-- Sensors & Devices -->
        <!-- <sensor-devices class="p-0 m-0 col-4" /> -->
      </div>

      <!-- <div class="row justify-content-between"> -->
      <!-- <div class="row col-12 m-0 p-0 justify-content-between mt-2 mb-2"> -->
      <!-- Maps -->
      <!-- <div
            v-show="config.location"
            class="row m-0 p-0 justify-content-between col-12 mb-2"
          >
            <div class="col-8 p-0 pr-1"> -->
      <!-- {{ currentLocation }} -->
      <!-- <map-location
                :current-plot-location="currentLocation"
                :on-site-location="onSiteLocation"
                class="m-0 p-0 mr-1"
              />
            </div>
            <on-site-location
              :locations="onSiteLocation"
              class="m-0 p-0 col-4"
              @newLocation="
                (selectedLocation) => {
                  currentLocation = selectedLocation;
                }
              "
            />
          </div>
        </div> -->
      <!-- </div> -->

      <!-- Manhours Report -->
      <!-- <div
        class="row pt-3 justify-content-between"
      >
        <project-team
          class="col-12"
        />
        <project-member
          class="col-12 mt-3"
        />
      </div> -->
    </div>

    <b-modal
      id="modal-cost-budget"
      content-class="shadow"
      hide-footer
      size="lg"
      @show="testShow()"
      @hidden="display.secondChart = null"
    >
      <div class="d-flex justify-content-center">
        <div
          class="btn-group bg-white row"
          role="group"
          aria-label="First group"
          style="width: 100%"
        >
          <div
            v-if="charData.title !== 'Time'"
            type="button"
            class="btn btn-light"
            :class="display.secondChart === false ? 'background-active' : ''"
            @click="display.secondChart = false"
          >
            Bar Chart
          </div>
          <div
            type="button"
            class="btn btn-light"
            :class="display.secondChart ? 'background-active' : ''"
            @click="display.secondChart = true"
          >
            S-Curve
          </div>
        </div>
      </div>

      <b-card no-body>
        <b-card-header>
          <!-- title and legend -->
          <b-card-title class="mb-50">
            {{ charData.title }}
          </b-card-title>
          <!--/ title and legend -->

          <!-- datepicker -->
          <div v-if="dataSafety">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range' }"
                class="
                  form-control
                  flat-picker
                  bg-transparent
                  border-0
                  shadow-none
                "
                placeholder="YYYY-MM-DD"
              />
            </div>
          </div>
          <!-- datepicker -->
        </b-card-header>

        <b-card-body>
          <apex-data-bar-chart
            v-if="display.secondChart === false && charData.title !== 'Time' "
            :chart-data="charData"
          />
          <scurve-chart
            v-if="display.secondChart"
            :chart-data="charDataScurve"
          />
        </b-card-body>
      </b-card>
    </b-modal>
    <b-modal
      id="modal-quality-issues"
      content-class="shadow"
      hide-footer
      size="lg"
      @hidden="display.secondChart = null"
    >
      <div
        v-if="display.secondChart === false || display.secondChart === false"
      >
        <apex-bar-chart :chart-data="charData" />
      </div>
    </b-modal>
  </div>

</template>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style>
.background-active {
  background-color: #00ae9d;
  color: white;
}
.cursorIcon {
  cursor: pointer;
}
.onHover {
  transition: transform 0.2s;
}
.onHover:hover {
  transform: scale(1.1);
  /* z-index: 55555; */
}
.col-half-offset {
  margin-left: 2%;
}
</style>
<script>
import {
  BModal,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue"
import { $themeColors } from "@themeConfig"
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app"
import Ripple from "vue-ripple-directive"
import flatPickr from "vue-flatpickr-component"
import GoalOverview from "./component/GoalOverview.vue"
// import TotalStatusChart from './component/TotalStatusChart.vue'
// import ManPowerTracker from './component/ManPowerTracker.vue'
import BudgetBarChart from "./component/BudgetBarChart.vue"
import WeeklyTask from "./component/WeeklyTask.vue"
// import SensorDevices from "./component/SensorDevices.vue"
import CardAnalyticRevenue from "./component/CardAnalyticRevenue.vue"
import NonComplianceIssues from "./component/NonComplianceIssues.vue"
// import MapLocation from "./component/mapLocation.vue"
// import OnSiteLocation from "./component/OnSiteLocation.vue"
// import ProjectTeam from "./component/ProjectTeam.vue"
// import ProjectMember from "./component/ProjectMember.vue"
// import StatisticCardHorizontal from './StatisticCardHorizontal.vue'
import ApexDataBarChart from "./component/ApexDataBarChart.vue"
import ApexBarChart from "./component/ApexBarChart.vue"
import scurveChart from "./component/scurveChart.vue"

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    flatPickr,
    GoalOverview,
    // TotalStatusChart,
    NonComplianceIssues,
    WeeklyTask,
    // SensorDevices,
    CardAnalyticRevenue,
    BudgetBarChart,
    // MapLocation,
    // OnSiteLocation,
    // ProjectTeam,
    // ProjectMember,
    // StatisticCardHorizontal,
    BModal,
    ApexDataBarChart,
    ApexBarChart,
    scurveChart,
  },
  data() {
    return {
      rangePicker: ["2019-05-01", "2019-05-10"],
      display: {
        secondChart: null,
      },
      chartColors: {
        column: {
          series1: "#826af9",
          series2: "#FF5733",
          bg: "#f8d3ff",
        },
        success: {
          shade_100: "#7eefc7",
          shade_200: "#06774f",
        },
        donut: {
          series1: "#ffe700",
          series2: "#00d4bd",
          series3: "#826bf8",
          series4: "#2b9bf4",
          series5: "#FFA1A1",
        },
        area: {
          series3: "#a4f8cd",
          series2: "#60f2ca",
          series1: "#2bdac7",
        },
      },
      charData: {
        title: "-",
        desc: "-",
        chartOptions: {},
        series: [],
      },
      charDataScurve: {
        title: "-",
        desc: "-",
        chartOptions: {},
        series: [],
      },
      tempSeries: [],
      tempSeriesObj: {},
      data: {},
      dataSafety: false,
      overview: {},
      totalStatus: {},
      projectTeam: [],
      weeklyTask: [],
      sensorDevices: [],
      onSiteLocation: [],
      currentLocation: {},
      config: {
        overviews: true,
        // location: true,
        budget: true,
        manpower: true,
        status: true,
        issues: true,
        weekTask: true,
        workHours: true,
        projTeam: true,
        membTeam: true,
      },
    }
  },
  created() {
    // data
    this.$http.get("/ecommerce/data").then(response => {
      this.data = response.data
    })

    this.$http.get("/analytics/data").then(response => {
      const dashboardData = response.data
      this.overview = dashboardData.dataOverview
      this.totalStatus = dashboardData.totalStatus.status
      this.projectTeam = dashboardData.listProjectTeam.projectTeam
      // this.onSiteLocation = dashboardData.onsiteLocationList.location

      // console.log(this.overview)

      // this.$axios.get(`${this.$baseUrl}/Dashboard/getCostOverview`).then(res => {
      // this.overview.type[0].actual = `${res.data.data.actual} Million`
      // this.overview.type[0].planning = `${res.data.data.planned} Million`
      // this.overview.type[0].actual = `${this.toMillion(res.data.data.actual)}`
      // this.overview.type[0].planning = `${this.toMillion(res.data.data.planned)}`
      // this.overview.type[0].series[0] = Math.round(res.data.data.percentage * 100) / 100
      // this.overview.type[0].series[0] = res.data.data.percentage.toFixed(2)
      // this.overview.type[0].total = `${res.data.data.percentage.toFixed(2)}%`
      // window.dispatchEvent(new Event('resize'))
      // })

      // this.$axios.get(`${this.$baseUrl}/Dashboard/getTimeOverview`).then(res => {
      // this.overview.type[1].actual = `${res.data.data.actual} Hours`
      // this.overview.type[1].planning = `${res.data.data.planned} Hours`
      // this.overview.type[1].series[0] = Math.round(res.data.data.percentage * 100) / 100
      // this.overview.type[1].series[0] = res.data.data.percentage.toFixed(2)
      // window.dispatchEvent(new Event('resize'))
      // })
      // this.$axios.get(`${this.$baseUrl}/Dashboard/getResourcesOverview`).then(res => {
      // this.overview.type[2].actual = (res.data.data.actual % 1 !== 0) ? `${res.data.data.actual.toFixed(2)}%` : `${res.data.data.actual}%`
      // this.overview.type[2].planning = `${res.data.data.planned}%`
      // this.overview.type[1].series[0] = Math.round(res.data.data.percentage * 100) / 100
      // this.overview.type[2].series[0] = res.data.data.percentage.toFixed(2)
      // window.dispatchEvent(new Event('resize'))
      // })
      // this.$axios.get(`${this.$baseUrl}/Dashboard/getQualityOverview`).then(res => {
      // this.overview.type[3].actual = `${res.data.data.actual}%`
      // this.overview.type[3].planning = `${res.data.data.planned}%`
      // this.overview.type[1].series[0] = Math.round(res.data.data.percentage * 100) / 100
      // this.overview.type[3].series[0] = res.data.data.percentage.toFixed(2)
      // window.dispatchEvent(new Event('resize'))
      // })
      // this.$axios.get(`${this.$baseUrl}/Dashboard/getSafetyOverview`).then(res => {
      // this.overview.type[4].actual = `${res.data.data.actual}`
      // this.overview.type[4].planning = `${res.data.data.planned}`
      // this.overview.type[1].series[0] = Math.round(res.data.data.percentage * 100) / 100
      // this.overview.type[4].series[0] = res.data.data.percentage.toFixed(2)
      // window.dispatchEvent(new Event('resize'))
      // })
    })

    if (localStorage.getItem("config") !== null) {
      this.config = JSON.parse(localStorage.getItem("config"))
      console.log("configgg")
      console.log(this.config)
    }
  },

  methods: {
    toMillion(labelValue) {
    // Nine Zeroes for Billions
      // eslint-disable-next-line no-nested-ternary
      return Math.abs(Number(labelValue)) >= 1.0e+9

        ? `${Math.abs(Number(labelValue)) / 1.0e+9} Billion`
        // eslint-disable-next-line no-nested-ternary
        : Math.abs(Number(labelValue)) >= 1.0e+6

          ? `${Math.abs(Number(labelValue)) / 1.0e+6} Million`
        // Three Zeroes for Thousands
          : Math.abs(Number(labelValue)) >= 1.0e+3

            ? `${Math.abs(Number(labelValue)) / 1.0e+3} Thousands`

            : Math.abs(Number(labelValue))
    },
    modalOpen(data) {
      console.log(data)
      console.log(this.dataSafety)
      if (data.name === "Safety") {
        this.dataSafety = true
        this.$axios.get(`${this.$baseUrl}/Dashboard/getSafetyCases`).then(res => {
          const dataCases = []
          const nameCases = []
          res.data.forEach(caseData => {
            dataCases.push(caseData.total)

            if (caseData.Type.length !== 0){
              nameCases.push(caseData.Type[0].label)
            } else {
              nameCases.push('unknown')
            }
          })

          this.charData = {
            ...this.charData,
            ...{
              title: data.concern,
              series: [
                {
                  name: '',
                  data: dataCases,
                },
              ],
              chartOptions: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },

                colors: $themeColors.info,
                plotOptions: {
                  bar: {
                    horizontal: true,
                    barHeight: "50%",
                    borderRadius: 5,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                xaxis: {
                  // type: "category",
                  categories: nameCases,
                  labels: {
                    formatter(val) {
                      return val
                    },
                    trim: true,
                    axisTicks: {
                      show: true,
                    },
                  },
                  tickAmount: 2,
                },
                yaxis: {
                  // opposite: isRtl,
                },
              },
            },
          }
        })
      } else {
        this.dataSafety = false
        console.log(data.name)
        this.charData = {
          title: data.concern,
          series: data.dataPlot,
          chartOptions: {
            chart: {
              stacked: false,
              toolbar: {
                show: false,
              },
            },
            colors: [
              this.chartColors.column.series1,
              this.chartColors.column.bg,
              '#FF0000',
            ],
            plotOptions: {
              bar: {
                columnWidth: "15%",
                borderRadius: 5,
                // colors: {
                //   backgroundBarRadius: 10,
                // },
                dataLabels: {
                  position: "top", // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: true,
              position: "top",
              fontSize: "14px",
              fontFamily: "Montserrat",
              horizontalAlign: "left",
            },
            stroke: {
              show: true,
              curve: "straight",
              // color: [this.chartColors.column.series2],
            },
            markers: {
              size: 4,
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: data.categories,
            },
            yaxis:
            // data.concern !== "Time Planning Budget"
            //   ? {
                  // opposite: isRtl,
                  {
                    labels: {
                      style: {
                        colors: "#b9b9c3",
                        fontSize: "1rem",
                      },
                      formatter(val) {
                        return data.name === 'Cost' ? `${val}M` : `${val}`
                      },
                    // formatter(val) {
                    //   return val > 999999 ? `${(val / 1000000).toFixed(0)} mil` : `${(val / 1000) * 100}%`
                    // },
                    },
                  },
            // : {
            //   labels: {
            //     style: {
            //       colors: "#b9b9c3",
            //       fontSize: "1rem",
            //     },
            //     formatter(val) {
            //       console.log(val)
            //       return `${val}M`
            //     },
            //   },
            // },
            fill: {
              opacity: 1,
            },
          },
        }

        this.charDataScurve = {
          title: data.concern,
          series: data.dataPlotScurve,
          chartOptions: {
            chart: {
              stacked: false,
              toolbar: {
                show: false,
              },
            },
            colors: [
              this.chartColors.column.series1,
              this.chartColors.column.bg,
              '#FF0000',
            ],
            plotOptions: {
              bar: {
                columnWidth: "15%",
                borderRadius: 5,
                // colors: {
                //   backgroundBarRadius: 10,
                // },
                dataLabels: {
                  position: "top", // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: true,
              position: "top",
              fontSize: "14px",
              fontFamily: "Montserrat",
              horizontalAlign: "left",
            },
            stroke: {
              show: true,
              curve: "straight",
              color: [this.chartColors.column.series2],
            },
            markers: {
              size: 4,
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: data.categories,
            },
            yaxis:
                  {
                    labels: {
                      style: {
                        colors: "#b9b9c3",
                        fontSize: "1rem",
                      },
                      formatter(val) {
                        return `${val} %`
                      },
                    },
                  },
            fill: {
              opacity: 1,
            },
          },
        }
      }

      setTimeout(() => {
        console.log('timeout heree')
        console.log(this.charData)
        if (this.charData.title === 'Time') {
          this.display.secondChart = true
        } else {
          this.display.secondChart = false
        }
      }, 100)
      switch (data.name) {
        // case 'Quality':
        //   this.$bvModal.show("modal-quality-issues")
        //   break
        case "Safety":
          this.$bvModal.show("modal-quality-issues")
          break
        default:
          this.$bvModal.show("modal-cost-budget")
      }
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    console.log(mqShallShowLeftSidebar)
    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>
